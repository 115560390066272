import './style.sass'
import axios from '@/axios'
import { DefaultTableExposed, TableHeader } from 'apptimizm-ui/src/ui/default-table/default-table'
import { DefaultInput, DefaultTable, ModalForm } from 'apptimizm-ui'
import { defineComponent, onMounted, Ref, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { DocumentEntity, DocumentMeta, ScannedProducts, ScannedProductsMeta } from './types'
import moment from 'moment'
import { EditInput } from '@/UI/edit-input'
import { mutations, store } from '@/store'
import AddProduct from './add-product'
import { cutText } from '@/core/cut-text'

export default defineComponent({
  setup () {
    const route = useRoute()
    const router = useRouter()
    const table = ref(null) as unknown as Ref<DefaultTableExposed>
    const documentMeta = new DocumentMeta()
    const scannedProductsMeta = new ScannedProductsMeta()
    const documentItem = ref<DocumentEntity>(new DocumentEntity())
    const showModal = ref<boolean>(false)
    const showModalDelete = ref<boolean>(false)
    const deleteItem = ref<ScannedProducts|null>(null)
    const screenWidth = ref<number>(0)

    const toggle = () => { showModal.value = !showModal.value }

    const toggleDeleteForm = () => { showModalDelete.value = !showModalDelete.value }

    const closeDeleteForm = () => {
      deleteItem.value = null
      showModalDelete.value = false
    }

    const tableFilter = {
      vendorCode: ref(''),
      barcode: ref(''),
      title: ref(''),
      get tableParams () {
        const res: { [code: string]: string } = {}
        if (store.currentProject.id) res.project = store.currentProject.id
        if (this.vendorCode.value) res.vendor_code = this.vendorCode.value
        if (this.barcode.value) res.barcode = this.barcode.value
        if (this.title.value) res.title = this.title.value

        return res
      }
    }

    const submit = async (item: ScannedProducts, refBtn: Ref<HTMLElement>) => {
      const { amount } = item
      const { errors } = (await axios.patch(
        scannedProductsMeta.endpointPatch + item.id, { amount })
      ).data

      if (errors) {
        mutations.pushNotification('Ошибка сохранения позиций документа', true)
      } else {
        mutations.pushNotification('Позиции документа сохранены')
        refBtn.value.style.opacity = '0'
        refBtn.value.style.visibility = 'hidden'
      }
    }

    const loadDocument = async () => {
      const { data } = await axios.get(documentMeta.endpoint + route.params.id)
      documentItem.value = documentMeta.load(data)
    }

    const deleteProduct = async () => {
      if (!deleteItem.value) return
      try {
        await axios.post(documentMeta.endpoint + documentItem.value.id + '/delete-product-position/', {
          product: deleteItem.value.id
        })
        mutations.pushNotification(`Товар ${deleteItem.value.product.name} успешно удалён.`)
      } catch (error) {
        mutations.pushNotification(`Ошибка удаления товара ${deleteItem.value.product.name}.`)
      }

      closeDeleteForm()
      table.value.reload()
    }

    watch(route, async () => {
      if (route.name !== 'documentation-id') return
      table.value && table.value.reload()
      loadDocument()
    })

    onMounted(async () => {
      if (!store.currentProject.id) router.push({ name: 'documentation' })
      else {
        screenWidth.value = window.innerWidth

        await loadDocument()
      }
    })

    const headers: TableHeader[] = [
      { name: 'Номер', minWidth: '15px', sort: 'number' },
      { name: 'Название товара', minWidth: '180px', sort: 'product__title' },
      { name: 'Код товара', minWidth: '130px', sort: 'product__vendor_code' },
      { name: 'Штрихкод', minWidth: '100px', sort: 'product__barcode' },
      { name: 'Датаматрикс', minWidth: '80px', sort: 'displayed_dm' },
      { name: 'Количество', minWidth: '150px', sort: 'amount' },
      { name: 'Время добавления позиции', minWidth: '160px', sort: 'scanned_time' },
      { name: '', minWidth: '40px' },
      { name: '', minWidth: '25px' }
    ]

    const line = (item: ScannedProducts) => {
      const refBtn = ref(item.id) as unknown as Ref<HTMLElement>
      const onChangeValue = (item: ScannedProducts, model: 'amount', value: number): void => {
        item[model] = value
        refBtn.value.style.opacity = '1'
        refBtn.value.style.visibility = 'visible'
      }

      return (
        <tr>
          <td>{item.number}</td>
          <td class='position-table-item-short'>
            {cutText(item.product.extendedTitle, screenWidth.value <= 1440 ? 15 : 35)}
            {(screenWidth.value <= 1440 && item.product.extendedTitle.length > 15) || (screenWidth.value > 1440 && item.product.extendedTitle.length > 35)
              ? <div class="table-hover">{item.product.extendedTitle}</div>
              : ''
            }
          </td>
          <td class='position-table-item-short'>
            {cutText(item.product.vendorCode, screenWidth.value <= 1440 ? 10 : 30)}
            {(screenWidth.value <= 1440 && item.product.vendorCode.length > 10) || (screenWidth.value > 1440 && item.product.vendorCode.length > 30)
              ? <div class="table-hover">{item.product.vendorCode}</div>
              : ''
            }
          </td>
          <td>{item.product.barcode}</td>
          <td>
            {cutText(item.displayedDm, screenWidth.value <= 1440 ? 10 : 30)}
            {(screenWidth.value <= 1440 && item.displayedDm.length > 10) || (screenWidth.value > 1440 && item.displayedDm.length > 30)
              ? <div class="table-hover">{item.displayedDm}</div>
              : ''
            }
          </td>
          <td>
            <EditInput
              modelValue={item.amount}
              onValueChange={(v: number) => { return onChangeValue(item, 'amount', v) }}
              onValueClear={() => { onChangeValue(item, 'amount', 0) }}
            />
          </td>
          <td>{moment(item.scannedTime).format('DD.MM.YYYY HH:mm:ss')}</td>
          <td>
            <button ref={refBtn} onClick={() => submit(item, refBtn)} class="button-string-edit">Готово</button>
          </td>
          <td style="display: flex; height: 100%;">
            <button
              class="button-delete-project"
              onClick={(e: Event) => {
                deleteItem.value = item
                toggleDeleteForm()
              }}
            >
              <img src={require('../../assets/image/delete-project.svg')}/>
            </button>
          </td>
        </tr>
      )
    }

    return (): JSX.Element => (
      <div class="layout position-page">
        <div class="close">
          <img class="close-svg" src={require('../../assets/image/close.svg')} alt="close" onClick={() => router.push({ name: 'documentation' })}/>
        </div>

        <div class='flexbar'>
          <div class="caption">
            <h1>Позиции документа №{documentItem.value.fakeId}</h1>
          </div>
          <button disabled={!store.currentProject.id} onClick={toggle} class='button-add'>Добавить</button>
        </div>

        <div class="position-page-top-panel-row">
          <DefaultInput
            class={tableFilter.title.value ? 'search input-placeholder-up' : 'search'}
            placeholder="Название"
            onValueChange={(v :string) => { tableFilter.title.value = v }}
            modelValue={tableFilter.title.value}
          />
          <DefaultInput
            class={tableFilter.vendorCode.value ? 'search input-placeholder-up' : 'search'}
            placeholder="Код товара"
            onValueChange={(v :string) => { tableFilter.vendorCode.value = v }}
            modelValue={tableFilter.vendorCode.value}
          />
          <DefaultInput
            class={tableFilter.barcode.value ? 'search input-placeholder-up' : 'search'}
            placeholder="Штрих-код товара"
            onValueChange={(v :string) => { tableFilter.barcode.value = v }}
            modelValue={tableFilter.barcode.value}
          />
        </div>

        <div class='table-wrapper lock-table-overflow'>
          <DefaultTable
            key={documentMeta.endpointScannedProduct + `?document=${route.params.id}`}
            axios={axios}
            endpoint={documentMeta.endpointScannedProduct + `?document=${route.params.id}`}
            defaultFilter={tableFilter.tableParams}
            ref={table}
            line={line}
            headers={headers}
            itemConverter={(v: ScannedProductsMeta) => ScannedProductsMeta.load(v)}
            responseItemsKey="results"
            responseTotalKey="count"
            paginationType="page"
            perPage={20}
          />
        </div>
        {showModal.value && <AddProduct documentId={documentItem.value.id} toggle={toggle} reload={table.value.reload}/>}

        {showModalDelete.value &&
          <ModalForm
            cancel={closeDeleteForm}
            confirm={() => {}}
            header={'Подтверждение'}
            slots={{
              default: () => (<p class="modal-text mt-3">Вы действительно хотите удалить { deleteItem.value?.product.title }?</p>),
              buttons: () => (<div class="mt-3">
                <div class="row">
                  <button onClick={closeDeleteForm} class="default-button">Нет, отменить</button>
                  <button onClick={deleteProduct} class="danger-button">Да, удалить</button>
                </div>
              </div>)

            }}
          />
        }
      </div>
    )
  }
})
