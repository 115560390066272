import { host } from '@/const'
import { Entity, EntityMeta } from 'apptimizm-ui'
import { User, UserMeta } from '../../core/user/types'
import axios from '@/axios'
import { mutations } from '@/store'

class ProjectStatisticZonesEnventory extends Entity {
  allZones: number = 0
  readyZones: number = 0
  percent: number = 0
}

class ProjectStatisticZonesEnventoryMeta extends EntityMeta<ProjectStatisticZonesEnventory> {
  fields = {
    allZones: { type: Number, backendKey: 'all_zones' },
    readyZones: { type: Number, backendKey: 'ready_zones' },
    percent: { type: Number, backendKey: 'percent' }
  }

  load (data: any): ProjectStatisticZonesEnventory {
    const result = super.load(data)
    if (data.percent === 0) result.percent = 0
    if (data.ready_zones === 0) result.readyZones = 0
    if (data.all_zones === 0) result.allZones = 0
    return result
  }
}

class ProjectStatisticTmcEnventory extends Entity {
  allsTmc: number = 0
  scannedTmc: number = 0
  percent: number = 0
}

class ProjectStatisticTmcEnventoryMeta extends EntityMeta<ProjectStatisticTmcEnventory> {
  fields = {
    allsTmc: { type: Number, backendKey: 'alls_tmc' },
    scannedTmc: { type: Number, backendKey: 'scanned_tmc' },
    percent: { type: Number, backendKey: 'percent' }
  }

  load (data: any): ProjectStatisticTmcEnventory {
    const result = super.load(data)
    if (data.percent === 0) result.percent = 0
    if (data.scanned_tmc === 0) result.scannedTmc = 0
    if (data.alls_tmc === 0) result.allsTmc = 0
    return result
  }
}

class ProjectStatistic extends Entity {
  productCount: number = 0
  barcodeCount: number = 0
  zoneCount: number = 0
  accountBalance: number = 0
  scanBegin: string = ''
  scanEnd: string = ''
  countedTmc: ProjectStatisticTmcEnventory | null = null
  countedZones: ProjectStatisticZonesEnventory | null = null
}

class ProjectStatisticMeta extends EntityMeta<ProjectStatistic> {
  fields = {
    productCount: { type: Number, backendKey: 'product_count' },
    barcodeCount: { type: Number, backendKey: 'barcode_count' },
    zoneCount: { type: Number, backendKey: 'zone_count' },
    accountBalance: { type: Number, backendKey: 'account_balance' },
    scanBegin: { type: String, backendKey: 'scan_begin' },
    scanEnd: { type: String, backendKey: 'scan_end' },
    countedTmc: { type: Object, backendKey: 'counted_tmc' },
    countedZones: { type: Object, backendKey: 'counted_zones' }
  }

  load (data: any): ProjectStatistic {
    const result = super.load(data)
    if (data.counted_tmc) result.countedTmc = new ProjectStatisticTmcEnventoryMeta().load(data.counted_tmc)
    if (data.counted_zones) result.countedZones = new ProjectStatisticZonesEnventoryMeta().load(data.counted_zones)

    return result
  }
}

class Project extends Entity {
  id: string = ''
  name: string = ''
  address: string = ''
  createdAt: string = ''
  manager: User = new User()
  rmmSettings: string = ''
  terminalSettings: string = ''
  template: string|null = ''
  templateExport: string|null = ''
  statistic: ProjectStatistic|null = null
  autoAssignEnbale: boolean = false
  accountingWithoutYk: boolean = false
  isManyBarcode: boolean = false
}

class ProjectMeta extends EntityMeta<Project> {
  endpoint: string = host + '/api/v1/project/'

  fields = {
    id: { type: String },
    name: { type: String, backendKey: 'title' },
    address: { type: String },
    createdAt: { type: String, backendKey: 'created_at' },
    manager: { type: UserMeta },
    rmmSettings: { type: String, backendKey: 'rmm_settings' },
    terminalSettings: { type: String, backendKey: 'terminal_settings' },
    template: { type: String },
    templateExport: { type: String, backendKey: 'template_export' },
    statistic: { type: Object },
    autoAssignEnbale: { type: Object, backendKey: 'auto_assign_enbale' },
    accountingWithoutYk: { type: Object, backendKey: 'accounting_without_yk' },
    isManyBarcode: { type: Boolean, backendKey: 'is_many_barcode' }
  }

  load (data: any): Project {
    const result = super.load(data)
    if (data.auto_assign_enbale === false) result.autoAssignEnbale = false
    if (data.auto_assign_enbale === true) result.autoAssignEnbale = true
    if (data.template === null) result.template = null
    if (data.template_export === null) result.templateExport = null
    if (data.accounting_without_yk === false) result.accountingWithoutYk = false
    if (data.accounting_without_yk === true) result.accountingWithoutYk = true
    if (data.statistic) {
      const meta = new ProjectStatisticMeta()
      result.statistic = meta.load(data.statistic)
    }
    return result
  }

  dump (item: Project): { [code: string]: any } {
    const result = super.dump(item)
    if (item.templateExport) result.template_export = item.templateExport
    if (!item.templateExport) delete result.template_export
    return result
  }

  async getProject (id: string) {
    try {
      const responseData = (await axios.get(`${this.endpoint}${id}/`)).data
      return this.load(responseData)
    } catch (error) {
      mutations.pushNotification('Не удалось получить информацию о проекте', true)
      return new Project()
    }
  }
}

class RmmSettings extends Entity {
  id: string = ''
  password: string = ''
  autoZonesAmount: number = 0
  extendedTasks: boolean = false
  norm: number = 0
}

class RmmSettingsMeta extends EntityMeta<RmmSettings> {
  endpoint: string = host + '/api/v1/rmm_settings/'

  fields = {
    id: { type: String },
    autoZonesAmount: { type: Number, backendKey: 'auto_zones_amount' },
    extendedTasks: { type: Boolean, backendKey: 'extended_tasks' },
    norm: { type: Number },
    password: { type: String }
  }

  load (item: any): RmmSettings {
    const result = super.load(item)
    if (!item.auto_zones_amount) result.autoZonesAmount = 0
    if (item.extended_tasks === null) result.extendedTasks = false
    if (item.norm === null) result.norm = 0

    return result
  }

  async getRmmSettings (id: string) {
    try {
      return this.load((await axios.get(`${this.endpoint}${id}`)).data)
    } catch (error) {
      mutations.pushNotification('Не удалось получить настойки РММ', true)
      return new RmmSettings()
    }
  }
}

interface ISelectValue { name: string, value: string }

class TerminalSettings extends Entity {
  id: string = ''
  complianceCodes: string = ''
  errorCorrection: boolean = false
  lengthBarcodePallets: string = ''
  suspiciousBarcodesAmount: number = 0
  trimmingBarcode: string = ''
  checkAm: ISelectValue = { name: '', value: '' }
  checkDm: ISelectValue = { name: '', value: '' }
  issuingTask: ISelectValue = { name: '', value: '' }
  productName: ISelectValue = { name: '', value: '' }
  recalculationDiscrepancy: ISelectValue = { name: '', value: '' }
  unknownBarcode: ISelectValue = { name: '', value: '' }
  searchByProductCode: boolean = false
  manualInputQuantity: boolean = false
  password = ''
  isScanningOfWeightProduct: boolean = false
}

class TerminalSettingsMeta extends EntityMeta<TerminalSettings> {
  endpoint: string = host + '/api/v1/terminal_settings/'

  fields = {
    id: { type: String },
    checkAm: { type: Object, backendKey: 'check_am' },
    checkDm: { type: Object, backendKey: 'check_dm' },
    complianceCodes: { type: String, backendKey: 'compliance_codes' },
    errorCorrection: { type: Boolean, backendKey: 'error_correction' },
    issuingTask: { type: Object, backendKey: 'issuing_task' },
    lengthBarcodePallets: { type: String, backendKey: 'length_barcode_pallets' },
    productName: { type: Object, backendKey: 'product_name' },
    recalculationDiscrepancy: { type: Object, backendKey: 'recalculation_discrepancy' },
    suspiciousBarcodesAmount: { type: String, backendKey: 'suspicious_barcodes_amount' },
    trimmingBarcode: { type: String, backendKey: 'trimming_barcode' },
    unknownBarcode: { type: Object, backendKey: 'unknown_barcode' },
    searchByProductCode: { type: Boolean, backendKey: 'search_by_product_code' },
    manualInputQuantity: { type: Boolean, backendKey: 'manual_input_quantity' },
    password: { type: String },
    isScanningOfWeightProduct: { type: Boolean, backendKey: 'is_scanning_of_weight_product' }
  }

  load (data: any): TerminalSettings {
    const result = super.load(data)
    if (data.compliance_codes === null) result.complianceCodes = ''
    if (data.error_correction === null) result.errorCorrection = false
    if (data.search_by_product_code === null) result.searchByProductCode = false
    if (data.length_barcode_pallets === null) result.lengthBarcodePallets = ''
    if (data.suspicious_barcodes_amount === null) result.suspiciousBarcodesAmount = 0
    if (data.trimming_barcode === null) result.trimmingBarcode = ''
    if (data.check_am === null) result.checkAm = { name: '', value: '' }
    if (data.check_dm === null) result.checkDm = { name: '', value: '' }
    if (data.issuing_task === null) result.issuingTask = { name: '', value: '' }
    if (data.product_name === null) result.productName = { name: '', value: '' }
    if (data.recalculation_discrepancy === null) result.recalculationDiscrepancy = { name: '', value: '' }
    if (data.unknown_barcode === null) result.unknownBarcode = { name: '', value: '' }
    if (data.manual_input_quantity === null) result.manualInputQuantity = false

    return result
  }

  dump (item: TerminalSettings) {
    const result = super.dump(item)

    if (item.issuingTask.value) result.issuing_task = item.issuingTask.value
    else result.issuing_task = null
    if (item.checkAm.value) result.check_am = item.checkAm.value
    else result.check_am = null
    if (item.checkDm.value) result.check_dm = item.checkDm.value
    else result.check_dm = null
    if (item.productName.value) result.product_name = item.productName.value
    else result.product_name = null
    if (item.recalculationDiscrepancy.value) result.recalculation_discrepancy = item.recalculationDiscrepancy.value
    else result.recalculation_discrepancy = null
    if (item.unknownBarcode.value) result.unknown_barcode = item.unknownBarcode.value
    else result.unknown_barcode = null

    if (item.complianceCodes === '') result.compliance_codes = null
    if (item.lengthBarcodePallets === '') result.length_barcode_pallets = null
    if (item.trimmingBarcode === '') result.trimming_barcode = null

    return result
  }
}

class Template extends Entity {
  id: string = ''
  name: string = ''
  fieldSeparator: string = ''
  decimalSeparator: string = ''
  storageName: string = ''
  singleExport: boolean = false
  fields: ISelectValue[] = []
  zoneNumberStart: number = 0
  zoneNumberEnd: number = 0
  isProductsFindByCode: boolean = false
  isProductsFindByQrCode: boolean = false
}

class TemplateMeta extends EntityMeta<Template> {
  endpoint: string = host + '/api/v1/template/'

  fields = {
    id: { type: String },
    name: { type: String, backendKey: 'title' },
    fieldSeparator: { type: String, backendKey: 'field_separator' },
    decimalSeparator: { type: String, backendKey: 'decimal_separator' },
    storageName: { type: String, backendKey: 'storage_name' },
    singleExport: { type: Boolean, backendKey: 'single_export' },
    fields: { type: Array },
    zoneNumberStart: { type: Number, backendKey: 'zone_number_start' },
    zoneNumberEnd: { type: Number, backendKey: 'zone_number_end' },
    isProductsFindByCode: { type: Boolean, backendKey: 'is_products_find_by_code' },
    isProductsFindByQrCode: { type: Boolean, backendKey: 'is_products_find_by_qr_code' }
  }

  load (data: any): Template {
    const result = super.load(data)
    result.fields = [...data.fields]

    return result
  }

  dump (item: Template) {
    const result = super.dump(item)
    let countND = 0
    let countAT = 0
    let countHTA = 0

    result.fields = item.fields.map(v => {
      if (v.value.includes('pin_additional_title')) {
        const s = v.value.split('--')[0]
        countAT++
        v.value = s + `_${countAT}`
      }
      if (v.value.includes('pin_not_download')) {
        const s = v.value.split('--')[0]
        countND++
        v.value = s + `_${countND}`
      }
      if (v.value.includes('pin_hidden_title_attr')) {
        const s = v.value.split('--')[0]
        countHTA++
        v.value = s + `_${countHTA}`
      }
      return v.value
    })

    if (!item.zoneNumberStart) result.zone_number_start = null
    if (!item.zoneNumberEnd) result.zone_number_end = null

    result.single_export = item.singleExport
    result.storage_name = result.storage_name === 'Все' ? null : result.storage_name

    console.log('result', result)

    return result
  }
}

class TemplateExportMeta extends TemplateMeta {
  endpoint: string = host + '/api/v1/template_export/'
}

export {
  Project,
  ProjectMeta,
  ProjectStatistic,
  ProjectStatisticMeta,
  ProjectStatisticTmcEnventory,
  ProjectStatisticTmcEnventoryMeta,
  ProjectStatisticZonesEnventory,
  ProjectStatisticZonesEnventoryMeta,
  RmmSettings,
  RmmSettingsMeta,
  TerminalSettings,
  TerminalSettingsMeta,
  Template,
  TemplateMeta,
  ISelectValue,
  TemplateExportMeta
}